import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useMessageStore from '../../../store/message-store';
import { IoMdSend } from "react-icons/io";
import { ButtonTASendMessage } from './button-ta-send-message/ButtonTASendMessage';
import { text } from 'stream/consumers';



interface KeyboardProps {
    disabled?: boolean;
}

export const Keyboard = (props: KeyboardProps) => {

    const { disabled } = props;


    const { addMessage, systemUnavailableMessage: isBlockKeyboard } = useMessageStore(state => state);
    const [content, setContent] = useState<string>('');
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const storeMessage = (message: string) => {
        setContent(message);
    };

    useEffect(() => {
        if (textareaRef.current) {
            const numberOfLineBreaks = (content.match(/\n/g) || []).length;
            // 20px es la altura base, más 20px adicionales por cada salto de línea
            let newHeight = 20 + numberOfLineBreaks * 20;
            // Asegura que la altura no exceda el máximo de 100px
            newHeight = newHeight > 100 ? 100 : newHeight;

            textareaRef.current.style.height = '40px'; // Resetea la altura para el cálculo
            const scrollHeight = textareaRef.current.scrollHeight;
            // Usa la altura más grande entre la calculada y la del scroll, pero no mayor a 100px
            const finalHeight = Math.min(Math.max(newHeight, scrollHeight), 100);



            textareaRef.current.style.height = `${finalHeight}px`; // Establece la nueva altura

            // Si la altura del scroll supera 100px, permitir el scrollbar
            if (scrollHeight > 100) {
                textareaRef.current.style.overflowY = 'scroll';
            } else {
                textareaRef.current.style.overflowY = 'hidden';
            }


            /** 
             * TODO: 
             * Este fragmento de código evita que el textarea se extienda a 100px de alto
             * cuando el contenido es visualizado a través de un iframe
             */
            if (content.length > 0) {
                textareaRef.current.style.maxHeight = '100px';
            }
        }

    }, [content]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {



        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();

            if (isBlockKeyboard) {
                setContent('');
                return;
            };

            addMessage(content);
            setContent(''); // Limpia el contenido directamente
        }
    };

    const sendMessageButton = () => {
        if (isBlockKeyboard) {
            setContent('');
            return;
        };

        addMessage(content);
        setContent('');

    };

    return (
        <div className='flex flex-col items-center justify-center w-full h-auto bg-transparent px-3'>
            <div
                className='keyboard-container relative w-full flex items-center justify-center mb-2'
                style={{
                    // height: '40px',
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: '20px',
                    border: '1px solid #e6e6e6',
                    transition: 'height 0.2s', // Suaviza la transición de altura
                    // zIndex: 99999999999, // Asegúrate de que este z-index sea mayor que el de otros elementos
                }}
            >
                <textarea
                    disabled={disabled}
                    ref={textareaRef}
                    placeholder={disabled ? '- MODO TEST ACTIVADO -' : 'Escribe un mensaje...'}
                    value={content}
                    className='custom-scrollbar w-full text-sm leading-5 resize-none outline-none border-none bg-white text-gray-800 rounded-3xl'
                    style={{
                        /**
                         * TODO:
                         * Evita que el text area se extienda a más de 40px cuando se inicia
                         * desde un iframe. Cuando se empieza a usar es cambiado a 100px
                         */
                        maxHeight: '40px',
                        overflow: 'hidden',
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        padding: '10px 101px 10px 15px',
                        // No establecer una altura fija aquí para permitir que el estilo en línea de altura tome control
                    }}
                    onChange={(e) => storeMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                    maxLength={140}
                />


                <ButtonTASendMessage sendMessageButton={sendMessageButton} />

            </div>
        </div>
    );
};
