import React, { useEffect } from "react";
import { TextMessage } from "../../../interfaces";
import { WrapperMessage } from "../../ui/wrapper-message/WrapperMessage";
import moment from "moment";
import clsx from "clsx";
import useConfigBotStore from "../../../store/config-bot-store";
import { getTextColor } from "../../../utils/colors/get-text-color";
import * as DOMPurify from 'dompurify';
import { WrapperMessage2 } from "../../ui/wrapper-message/WrapperMessage2";
/**
 * Ejemplo
 * 
 * {
 *   "text": [
 *     "Este es un mensaje de texto",
 *     "Este es otro mensaje, saldrá uno de los dos"
 *   ],
 *   "message": "text"
 * }
 * 
 */

interface BotTextProps {
    message: TextMessage;
}


export const BotText = (props: BotTextProps) => {

    const MAX_CHARACTERS_FOR_SINGLE_LINE = 36;
    const { message } = props;

    const configBot = useConfigBotStore(state => state.configBot);

    const randomIndex = Math.floor(Math.random() * message.text.length);
    const selectedText = message.text[randomIndex];
    const cleanedText = DOMPurify.sanitize(selectedText, {
        ADD_ATTR: ['target', 'rel'],
    });

    // console.log("mira el texto limpio", cleanedText)

    const dateFormatted = moment(message.date).format('HH:mm');

    const isShortText = selectedText.length <= MAX_CHARACTERS_FOR_SINGLE_LINE;


    return (
        <WrapperMessage2 userType={message.userType}>
            <div className={clsx("flex", {
                "flex-row items-center justify-between": isShortText, // Si el texto es corto, usa flex-row
                "flex-col": !isShortText // Si el texto es largo, usa flex-col
            })}>
                {/* Contenido principal */}
                {/* <span className="text-black text-sm" style={{ color: message.userType === 'human' ? getTextColor(configBot?.color3) : "" }} > {selectedText}</span> */}
                <span className="text-black text-sm"
                    dangerouslySetInnerHTML={{ __html: cleanedText }}
                    style={
                        {
                            color: message.userType === 'human' ? getTextColor(configBot?.color3) : "",
                            whiteSpace: 'pre-line',
                        }} />

                {/* Fecha formateada */}
                {isShortText ? (
                    <span className=" ml-1 self-end" style={{ fontWeight: 600, fontSize: 11 }}>{dateFormatted}</span>
                ) : (
                    <div className="w-full text-right">
                        <span className=" ml-1 self-end" style={{ fontWeight: 600, fontSize: 11 }}>{dateFormatted}</span>
                    </div>
                )}
            </div>
        </WrapperMessage2 >
    );
}


export const BotTextMemo = React.memo(BotText);
