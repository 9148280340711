import { BotMessage, FileType, MessageType, UserType } from "./bot-message.interface";
import { Button } from "./button.interface";



export class QuickreplyMessage extends BotMessage {
    title: string[];
    message: MessageType;
    url: string;
    buttons: Button[];
    fileType: FileType;

    constructor(userType: UserType = 'bot', quickreplyMessage: QuickreplyMessage) {
        super(userType);
        this.title = quickreplyMessage.title;
        this.message = 'quickreply';
        this.url = quickreplyMessage.url;
        this.buttons = quickreplyMessage.buttons;
        this.fileType = quickreplyMessage.fileType;
    }

    getMessage(): BotMessage | undefined {
        return this;
    }
}

// export interface buttons {
//     title: string;
//     isLink: boolean;
//     shadowMessage: null | string;
//     value: string;
// }



