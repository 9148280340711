
import { useState } from "react";
import { useConfigBotStore, useMessageStore, useOpenChatStore } from "../../../../store";
import { getContrastColor } from "../../../../utils/colors/adjust-color"
import { sendMessageToIframe } from "../../../../utils/sendMessageToIframe";
import { IoMdSend } from "react-icons/io";


interface ButtonTASendMessageProps {
    sendMessageButton: () => void;
}


export const ButtonTASendMessage = ({ sendMessageButton }: ButtonTASendMessageProps) => {



    const configBot = useConfigBotStore(state => state.configBot);

    const [isHover, setIsHover] = useState<boolean>(false);


    const handleMouseEnter = () => setIsHover(true);
    const handleMouseLeave = () => setIsHover(false);



    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="absolute right-2 w-[33px] h-[33px] rounded-full flex justify-center items-center cursor-pointer"
            style={{ backgroundColor: isHover ? getContrastColor(configBot.color1, 180) : "transparent" }}
            onClick={sendMessageButton}>

            <IoMdSend className='antialiased' color={configBot.color1} />
        </div>
    )
}

