export const updateGradientColor = (colorHex: any) => {
    if (!colorHex) return;
    const colorRgb = hexToRgb(colorHex);
    document.documentElement.style.setProperty('--color-gradient-glassmorphism', colorRgb);
}

const hexToRgb = (hex: any) => {
    let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    return `${r}, ${g}, ${b}`; // Retorna el color en formato RGB
}