import { create } from 'zustand';
import { persist } from "zustand/middleware";
import {  ConfigBot } from '../interfaces';
import { config } from 'dotenv';


type ConfigBotStore = {
    configBot: ConfigBot;
    hasWelcome: boolean;
    addConfigBot: (configBot: ConfigBot) => void;
    setHasWelcome: (hasWelcome: boolean) => void;
};

const useConfigBotStore = create<ConfigBotStore>(
    (set, get) => ({
        hasWelcome: false,
        configBot: null,
        addConfigBot: (configBot) => {
            if (configBot && configBot.welcomeMessage) {
                set({ hasWelcome: true });
            }
            set({ configBot });
        },
        setHasWelcome: (hasWelcome) => {
            set({ hasWelcome });
        },
    }),

);

export default useConfigBotStore;
