import { useState, useEffect } from 'react';

export const useStoreHasWelcome = (): { hasWelcomeHidden: boolean; storeWelcomeHidden: () => void; } => {
    const [hasWelcomeHidden, setHasWelcomeHidden] = useState(false);

    useEffect(() => {
        const hasWelcomeStored = sessionStorage.getItem('welcome-hidden');
        if (hasWelcomeStored) {
            setHasWelcomeHidden(true);
        }
    }, []);

    const storeWelcomeHidden = () => {
        sessionStorage.setItem('welcome-hidden', 'true');
    }

    return { hasWelcomeHidden, storeWelcomeHidden };
};


