import { BotMessage, CardMessage, QuickreplyMessage, TextMessage } from "../../../interfaces"
import { BotCardMemo } from "../bot-card/BotCard"
import { BotCardMemo2 } from "../bot-card/BotCard2"
import { BotQuickreplyMemo } from "../bot-quickreply/BotQuickreply"
import { BotTextMemo } from "../bot-text/BotText"

interface BotMessageSelectorProps {
    message: BotMessage
}


export const BotMessageSelector = ({ message }: BotMessageSelectorProps) => {
    switch (message.message) {
        case 'text':
            return <BotTextMemo message={message as TextMessage} />
        case 'quickreply':
            return <BotQuickreplyMemo message={message as QuickreplyMessage} />
        case 'card':
            // return <BotCardMemo message={message as CardMessage} />
            return <BotCardMemo2 message={message as CardMessage} />
        default:
            return (<></>)

    }

}