import { BotMessage, MessageType, UserType } from "./bot-message.interface";



export class TextMessage extends BotMessage {
    text: string[];
    message: MessageType;

    constructor(userType: UserType = 'bot', text: string[] = []) {
        super(userType);
        this.text = text;
        this.message = 'text';
    }

    getMessage(): TextMessage | undefined {
        return this;
    }
}
