import { useEffect, useState } from "react";
import useConfigBotStore from "../../../store/config-bot-store";
import { useSpring, animated } from '@react-spring/web';
import { RxCross2 } from "react-icons/rx";
import clsx from "clsx";
import { getTextColor } from "../../../utils/colors/get-text-color";
import { adjustColor, getContrastColor } from "../../../utils/colors/adjust-color";
import { config } from "dotenv";
import { WelcomeBubbleType, WelcomeSlideType } from "../..";
import useOpenChatStore from "../../../store/open-chat-store";
import { sendMessageToIframe } from "../../../utils/sendMessageToIframe";
import { useStoreHasWelcome } from "../../../hooks";



export const ChatFAB = () => {

    const configBot = useConfigBotStore(state => state.configBot);
    const { setOpenChat } = useOpenChatStore(state => state);
    const { hasWelcomeHidden } = useStoreHasWelcome();


    const [set] = useSpring(() => ({ scale: { x: 0, y: 0 } }));

    console.log("Config.logo", configBot.logo)

    // useEffect(() => {
    //     sendMessageToIframe({
    //         action: 'rbc-open-chat', payload: {
    //             isOpen: false,
    //             messageType: hasWelcomeHidden ? false : configBot.welcomeMessage?.type,

    //         }
    //     });

    // }, [])

    const renderContent = () => {
        switch (configBot?.welcomeMessage?.type) {
            case 'slide':
                return <WelcomeSlideType configBot={configBot} />;
            case 'bubble':
                return <WelcomeBubbleType configBot={configBot} />;
            default:
                return <></>
        }
    }

    const handleOpenChat = () => {
        sendMessageToIframe({
            action: 'rbc-open-chat', payload: {
                isOpen: true
            }
        });
        setOpenChat(true);
    }

    // Definición de la animación
    const animation = useSpring({
        from: { transform: 'scale(0)' },
        to: { transform: 'scale(1)' },
        config: { duration: 150 },
    });

    const [style, setStyle] = useSpring(() => ({
        // transform: 'scale(1)',
        width: '60px',
        height: '60px',
        config: { tension: 300, friction: 15 },
    }));


    if (configBot.environment === 'production') {

        return (
            <div className="relative">
                <div className="circle-container fixed bottom-0 right-0 m-0 bg-transparent">
                    <div className="absolute circle-pointer cursor-pointer" onClick={handleOpenChat} />
                    {/* <svg className="circle-svg" viewBox="0 0 150 150"> */}
                    <svg className="circle-svg" viewBox="0 0 150 150"  >

                        <circle cx="75" cy="75" r="58" stroke="#e0eeee" strokeWidth="1" fill="none" />
                        <circle cx="75" cy="75" r="38" stroke="#e0eeee" strokeWidth="1" fill="none" />
                        <g className="rotating-text ">
                            <text className="circle-text" textAnchor="start">
                                <textPath href="#circlePath" startOffset="10%">
                                    ¡HABLEMOS!
                                </textPath>
                            </text>

                            <text className="circle-dot" textAnchor="start">
                                <textPath href="#circlePath" startOffset="50%">
                                    •
                                </textPath>
                            </text>

                            <text className="circle-text" textAnchor="start">
                                <textPath href="#circlePath" startOffset="60%">
                                    ¡HABLEMOS!
                                </textPath>
                            </text>


                            <text className="circle-dot " textAnchor="start">
                                <textPath href="#circlePath" startOffset="0%">
                                    •
                                </textPath>
                            </text>
                        </g>
                        <defs>
                            <path id="circlePath" d="M 75, 75 m -43, 0 a 40,40 0 1,1 85,0 a 40,40 0 1,1 -85,0" />
                        </defs>
                        <image className="" href={"logo-rbc-web_colors_transparent.png"} x="36" y="36" height="80" width="80" />
                    </svg>

                </div>
            </div>
        )
    }

    return (

        <animated.div
            onMouseEnter={() => {
                const hasWelcomeStored: any = sessionStorage.getItem('welcome-hidden');

                if (!hasWelcomeStored) return;
                setStyle({
                    width: '63px',
                    height: '63px',
                });

            }}
            onMouseLeave={() => setStyle({
                width: '60px',
                height: '60px',
            })}
            style={{ ...animation }}
            className="flex items-center justify-end fixed bottom-5 right-5">
            {configBot.welcomeMessage && renderContent()}

            <animated.div
                style={style}
                onClick={handleOpenChat}
                className="flex items-center justify-center rounded-full cursor-pointer shadow-lg w-[60px] h-[60px] overflow-hidden z-50">
                <img src={configBot.logo} alt="logo_bot" className="w-full h-full object-cover" />
            </animated.div>

        </animated.div>



    );


}

