import clsx from "clsx";
import { Button } from "../../../interfaces";
import useConfigBotStore from "../../../store/config-bot-store";
import { getTextColor } from "../../../utils/colors/get-text-color";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { adjustColor } from "../../../utils/colors/adjust-color";
import useMessageStore from "../../../store/message-store";
import { animated, useSpring, useSprings } from '@react-spring/web'


export interface ButtonMessageProps {
    buttons: Button[];
}

export const ButtonsMessage2 = ({ buttons }: ButtonMessageProps) => {




    // Estado y referencias
    const configBot = useConfigBotStore(state => state.configBot);
    const { addMessage, messages } = useMessageStore(state => state);

    const [hoverIndex, setHoverIndex] = useState(null);
    const [selectedButton, setSelectedButton] = useState<number | null>(null);
    const [arrayElements, setArrayElements] = useState<any[]>([]);
    const [heightContainer, setHeightContainer] = useState<number>(undefined);
    const [hasNewMessage, setHasNewMessage] = useState<any>(false);

    const [removedButtons, setRemovedButtons] = useState<number[]>(buttons.map((_, i) => i));
    const [finalTransform, setFinalTransform] = useState<{ x: number, y: number }>({ x: 0, y: 0 });

    const buttonsContainerRef = useRef(null);

    const [springs, api] = useSprings(buttons.length, index => ({
        opacity: 1,
        transform: 'translateX(0px) translateY(0px)',
        position: 'relative',
    }));


    // Manejadores de eventos para mouse
    const handleMouseEnter = (index: number) => setHoverIndex(index);
    const handleMouseLeave = () => setHoverIndex(null);


    // Lógica al seleccionar un botón
    const handleMessage = (button: Button, index: number) => {
        if (selectedButton) return;


        setSelectedButton(index);

        let xOffset = 0;
        let yOffset = 0;

        for (const row of arrayElements) {
            let found = false;

            for (const button of row) {
                const rect = button.getBoundingClientRect();
                const style = window.getComputedStyle(button);
                const marginLeft = parseInt(style.marginLeft, 10);
                const marginRight = parseInt(style.marginRight, 10);
                const marginTop = parseInt(style.marginTop, 10);
                const marginBottom = parseInt(style.marginBottom, 10);

                if (button.dataset.buttonId === `button-${index}`) {
                    found = true;
                } else if (found) {
                    xOffset += rect.width + marginLeft + marginRight;
                }
            }

            if (found) {
                break;
            } else {
                const firstButtonStyle = window.getComputedStyle(row[0]);
                const rowMarginTop = parseInt(firstButtonStyle.marginTop, 10);
                const rowMarginBottom = parseInt(firstButtonStyle.marginBottom, 10);
                yOffset += row[0].getBoundingClientRect().height + rowMarginTop + rowMarginBottom;
            }
        }

        // api.start(i => {
        //     if (index === i) {
        //         return {
        //             opacity: 1,
        //             transform: `translateX(${xOffset}px) translateY(${-yOffset}px)`,
        //             config: { duration: 300 },
        //             position: 'absolute',
        //             // zIndex: -99
        //         };
        //     } else {
        //         return {
        //             opacity: 0,
        //             config: { duration: 100 },
        //             // zIndex: -98
        //         };
        //     }
        // });

        const DURATION = 300;

        api.start(i => {
            if (index === i) {
                return {
                    opacity: 1,
                    transform: `translateX(${xOffset}px) translateY(${-yOffset}px)`,
                    config: { duration: DURATION },
                    position: 'absolute',
                };
            } else {
                return {
                    opacity: 0,
                    config: { duration: 100 },
                    position: 'relative', // Asegurar que la posición sea relativa para todos los botones
                };
            }
        });

        // vv Esto es necesario hacerlo para PROD
        setTimeout(() => {
            setRemovedButtons(prev => prev.filter(i => i === index));
        }, DURATION + 200);
        // ^^ Esto es necesario hacerlo para PROD


        setTimeout(() => {
            const selectedButtonElement = document.querySelector(`[data-button-id="button-${index}"]`);
            if (selectedButtonElement) {
                const selectedButtonRect = selectedButtonElement.getBoundingClientRect();
                setHeightContainer(selectedButtonRect.height + 16);
            }
            if (button.isLink) {
                if (button.shadowMessage) {
                    addMessage(button.shadowMessage, true)
                }
            } else {
                if (button.value) {
                    /**
                     * En el caso de las Quickreply, por el tema del botón animado todos valor que se envie 
                     * al back es considerado shadowMessage
                     */
                    const isShadowMessage = true;
                    addMessage(button.value, isShadowMessage);
                }

                if (button.shadowMessage) {
                    addMessage(button.shadowMessage, true);
                }
            }
        }, 400)
    }

    useEffect(() => {
        if (buttonsContainerRef.current) {
            const containerRect = buttonsContainerRef.current.getBoundingClientRect();
            const buttonElements = buttonsContainerRef.current.querySelectorAll('div'); // Asumiendo que los botones son 'div'

            setHeightContainer(containerRect.height);
            // Objeto para agrupar los botones por su posición 'y'
            let rows: any[] = [];

            buttonElements.forEach((button: any) => {
                const rect = button.getBoundingClientRect();
                // Usar el valor 'y' como clave para agrupar botones en la misma fila
                const yKey = rect.top.toFixed(); // Redondeamos para evitar problemas de precisión de punto flotante

                // Si no existe una fila para esta clave 'y', crearla
                if (!rows[yKey]) {
                    rows[yKey] = [];
                }

                // Añade el botón al array de su respectiva fila
                rows[yKey].push(button);
            });

            // Convertimos el objeto de filas en un array de arrays
            const arrayOfRows = Object.values(rows);

            setArrayElements(arrayOfRows);

            // console.log(arrayOfRows);

            // console.log(buttonElements); // Esto mostrará un NodeList de todos los elementos 'div' dentro del contenedor
            // console.log("EFFECT", containerRect)
        }
    }, [buttonsContainerRef]);


    useEffect(() => {
        if (!hasNewMessage && messages?.length > 0) {
            if (messages[messages.length - 1].userType === 'human' as any) {
                setHasNewMessage(true);
            }
        }
    }, [messages])

    if (hasNewMessage && !selectedButton) return null;


    return (

        // <div className={clsx("flex flex-wrap py-2", {
        //     'justify-center': buttons.length <= 2,
        //     'flex-col w-full': buttons.length > 2
        // })}>
        <div
            id="buttons-container"
            ref={buttonsContainerRef}
            className={clsx("container-transition flex justify-end flex-wrap px-3 relative w-full transition-all duration-0")}
            style={{ height: heightContainer }}
        >
            {buttons?.map((button, indx) => {

                // vv Esto es necesario hacerlo para PROD
                if (!removedButtons.includes(indx)) return null;
                // ^^ Esto es necesario hacerlo para PROD

                const idbutton = `button-${indx}`
                const isSelected = indx === selectedButton;
                const backgroundColor = isSelected ? configBot.color3 : (indx === hoverIndex ? configBot.color2 : "");
                const borderColor = isSelected ? configBot.color3 : configBot.color2;


                // vv Esto es necesario hacerlo para PROD
                let style = {};
                if (selectedButton === indx && removedButtons.length === 1) {
                    style = {
                        height: '20x', // Ajusta este valor según sea necesario
                        transform: `translateX(${finalTransform.x}px) translateY(${finalTransform.y}px)`,
                        position: 'absolute',
                    };
                }
                // ^^ Esto es necesario hacerlo para PROD



                return (
                    <animated.div
                        key={indx}
                        data-button-id={idbutton}
                        onMouseEnter={() => handleMouseEnter(indx)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleMessage(button, indx)}

                        style={{
                            ...springs[indx],
                            ...style,
                            // position: selectedButton === indx ? 'relative' : 'relative',
                            position: selectedButton === indx ? 'relative' : 'relative',
                            backgroundColor: backgroundColor,
                            borderColor: borderColor,
                            cursor: 'pointer',

                        } as any}
                        className={clsx('flex px-4 py-2.5 my-2 rounded-2xl m-1 border transition-colors duration-300 ease-in-out')}>

                        <span

                            className="text-center w-full transition-colors duration-300 ease-in-out text-sm"
                            style={{ color: getTextColor(backgroundColor) }}
                        // onClick={() => handleMessage(button.value, button.shadowMessage, indx)}

                        // Este de abajo es el bueno 3 de marzo 2024
                        // onClick={() => handleMessage(button, indx)}
                        >
                            {button.title}
                        </span>
                    </animated.div>
                )
            })}
        </div>
    )
}



