

export const LoadingDots = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    );
  };
