import CryptoJS from 'crypto-js';

const secretKeyString = process.env.REACT_APP_SECRET_KEY_RESPONSE;

if (!secretKeyString) {
    throw new Error('REACT_APP_SECRET_KEY_RESPONSE no está definida en el archivo .env');
}

// Asegurémonos de limpiar cualquier carácter extraño
const cleanedSecretKeyString = secretKeyString.replace(/[^a-zA-Z0-9!@#$%^&*()-_+=]/g, '');

const secretKey = CryptoJS.enc.Utf8.parse(cleanedSecretKeyString);

export namespace KeepSafeResponse {
    // export const encrypt = (object: object): string => {
    //     const text = JSON.stringify(object); // Convertir el objeto a una cadena JSON
    //     const iv = CryptoJS.lib.WordArray.random(16); // Generar un IV aleatorio
    //     const encrypted = CryptoJS.AES.encrypt(text, secretKey, {
    //         iv: iv,
    //         mode: CryptoJS.mode.CTR,
    //         padding: CryptoJS.pad.NoPadding
    //     });

    //     return iv.toString(CryptoJS.enc.Hex) + ':' + encrypted.ciphertext.toString(CryptoJS.enc.Hex);
    // };

    export const decrypt = (hash: string): object => {
        console.log("secretKeyString 2", secretKeyString);
        console.log("cleanedSecretKeyString", cleanedSecretKeyString);
        console.log("secretKey", secretKey.toString(CryptoJS.enc.Hex));
        console.log('Tipo de hash:', typeof hash);
        console.log('Valor de hash:', hash);

        if (typeof hash !== 'string') {
            throw new TypeError(`hash debe ser una cadena. Tipo recibido: ${typeof hash}`);
        }

        const parts = hash.split(':');
        if (parts.length !== 2) {
            throw new Error('Formato de hash inválido. Se esperaba "iv:encryptedText".');
        }

        const [iv, encryptedText] = parts;

        const cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Hex.parse(encryptedText),
            iv: CryptoJS.enc.Hex.parse(iv)
        });

        const decrypted = CryptoJS.AES.decrypt(
            cipherParams,
            secretKey,
            {
                iv: CryptoJS.enc.Hex.parse(iv),
                mode: CryptoJS.mode.CTR,
                padding: CryptoJS.pad.NoPadding
            }
        );

        const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
        console.log('Decrypted String:', decryptedString);

        return JSON.parse(decryptedString); // Convertir la cadena JSON de vuelta a un objeto
    };
}
