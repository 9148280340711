import { useState, useEffect, useRef } from "react";
import { useSpring, animated } from '@react-spring/web';
import { ConfigBot } from "../../../../interfaces";
import { getContrastColor } from "../../../../utils/colors/adjust-color";
import { getTextColor } from "../../../../utils/colors/get-text-color";
import { RxCross2 } from "react-icons/rx";
import { useStoreHasWelcome } from "../../../../hooks";
import { sendMessageToIframe } from "../../../../utils/sendMessageToIframe";

interface WelcomeBubbleTypeProps {
    configBot: ConfigBot;
}

export const WelcomeBubbleType = ({ configBot }: WelcomeBubbleTypeProps) => {

    const { storeWelcomeHidden } = useStoreHasWelcome();

    const messageRef = useRef(null);
    const [messageHeight, setMessageHeight] = useState(0);
    const HEIGHT_MINIMUN = 102;

    const [message, setMessage] = useState("");
    const [isOpenMessage, setIsOpenMessage] = useState(false);
    const [isHover, setIsHover] = useState<boolean>(false);

    const LIMIT_CHARACTERS = 120;

    useEffect(() => {
        let timer: any;
        const hasWelcomeStored = sessionStorage.getItem('welcome-hidden');

        if (configBot.welcomeMessage && !hasWelcomeStored) {
            const messageWelcome = LIMIT_CHARACTERS > configBot.welcomeMessage?.text?.length
                ? configBot.welcomeMessage?.text
                : configBot.welcomeMessage.text.slice(0, LIMIT_CHARACTERS) + "...";

            setMessage(messageWelcome);

            timer = setTimeout(() => {
                setIsOpenMessage(true);
            }, configBot.welcomeMessage.delay || 100);
        }

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [configBot.welcomeMessage]);

    const fadeProps = useSpring({
        opacity: isOpenMessage ? 1 : 0,
        config: { duration: 300 } // Ajusta la duración de la animación de fade como prefieras
    });

    // Manejadores de eventos para mouse
    const handleMouseEnter = () => setIsHover(true);
    const handleMouseLeave = () => setIsHover(false);


    const handleCloseMessage = () => {
        setIsOpenMessage(false);
        storeWelcomeHidden();
        sendMessageToIframe({
            action: 'rbc-open-chat', payload: {
                isOpen: false,
                messageType: null,
                size: null
            }
        });
    };


    // Siempre inicia
    useEffect(() => {
        if (messageRef.current) {
            const scrollHeight = messageRef.current.scrollHeight; // Altura del contenido
            setMessageHeight(scrollHeight); // Actualiza el estado con la nueva altura
            const hasWelcomeStored = sessionStorage.getItem('welcome-hidden');

            sendMessageToIframe({
                action: 'rbc-open-chat', payload: {
                    isOpen: false,
                    messageType: hasWelcomeStored ? false : configBot.welcomeMessage?.type,
                    size: hasWelcomeStored
                        ? null
                        : {
                            height: scrollHeight + HEIGHT_MINIMUN,
                            width: null
                        }
                }
            });
        }
    }, [message])

    return (
        <animated.div
            ref={messageRef}
            style={{ ...fadeProps, ...{ backgroundColor: getContrastColor(configBot.color3) } }}
            className="absolute bottom-20 rounded-md shadow-md flex">
            <p
                id="message"
                className="ml-2 whitespace-pre-wrap text-sm w-[200px] p-2 overflow-x-hidden"
                style={{ color: getTextColor(configBot.color3), overflowY: 'hidden' }}>
                {message}
            </p>
            <div
                onClick={handleCloseMessage}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="flex items-center justify-center w-[30px] rounded-r-lg cursor-pointer"
                style={{ backgroundColor: isHover ? configBot.color3 : configBot.color3 }}
            >
                {/* <div className="w-px h-[85%]" style={{ backgroundColor: getTextColor(configBot.color3) }} /> */}
                <RxCross2 size={18} className="text-white mx-1" style={{ color: getTextColor(configBot.color3) }} />

            </div>



        </animated.div>
    )

}
