import { create } from 'zustand';

type OpenChatStore = {
    origin: string;
    setOrigin: (value: string) => void;
};


const useBotActivationBot = create<OpenChatStore>(
    (set, get) => ({
        origin: undefined,
        setOrigin: (value) => {
            set({ origin: value });
        },
    }));

export default useBotActivationBot;
