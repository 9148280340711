import { useRef, useEffect } from "react";

interface LegalSectionProps {
    childrenLogo: React.ReactNode;
    children: React.ReactNode;
}

export const LogoFollower = (props: LegalSectionProps) => {
    const { childrenLogo, children } = props;


    return (
        <div className="w-full flex flex-row relative py-2.5">
            <div className="sticky bottom-10 self-end">
                {childrenLogo}
            </div>

            {children}

        </div>
    );
};
