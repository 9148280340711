import React, { useCallback, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { Button, CardMessage, Item } from '../../../interfaces';
import { MultimediaContent } from '../../multimedia/multimedia-content/MultimediaContent';
import clsx from 'clsx';
import { adjustColor } from '../../../utils/colors/adjust-color';
import { getTextColor } from '../../../utils/colors/get-text-color';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperObject } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper/modules';
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import { FaExternalLinkAlt } from "react-icons/fa";


import useConfigBotStore from '../../../store/config-bot-store';
import useMessageStore from '../../../store/message-store';
import { UtilGeneral } from '../../../utils/util-general';


interface BotCardProps {
    message: CardMessage;
}



const NextButton = () => (
    <div className="image-swiper-button-next -mr-7 antialiased">
        {/* Aquí puedes poner tu SVG o cualquier otro elemento */}
        <SlArrowRight size={30} />
    </div>
);

const PrevButton = () => (
    <div className="image-swiper-button-prev antialiased">
        {/* Aquí puedes poner tu SVG o cualquier otro elemento */}
        <SlArrowLeft size={30} />
    </div>
);

export const BotCard = ({ message }: BotCardProps) => {
    const configBot = useConfigBotStore(state => state.configBot);
    const { items } = message;
    return (
        <div className="flex flex-row relative py-2.5">
            <div className='flex px-3'>
                <div className='flex items-end justify-center'>
                    <span className='rounded-full bg-gray-950 w-[35px] h-[35px] overflow-hidden'>
                        <img src={configBot.logo} alt="logo_bot" className="w-full h-full object-cover" />
                    </span>
                </div>
            </div>
            <NextButton />
            <PrevButton />
            <Swiper
                style={{
                    '--swiper-navigation-color': '#000',
                    '--swiper-pagination-color': '#000',
                    // left: items.length === 1 ? -30 : '',
                    // marginLeft: items.length === 1 ? -10 : '',

                    // marginLeft: 0,
                    // marginRight: 0,

                } as React.CSSProperties}
                slidesPerView={1}
                // spaceBetween={10}
                spaceBetween={items.length === 1 ? 10 : -10}

                centeredSlides={true}
                navigation={{
                    nextEl: ".image-swiper-button-next",
                    prevEl: ".image-swiper-button-prev",
                }}
                className="mySwiper"
                freeMode={true}

                modules={[FreeMode, Navigation]}


            >
                {items.map((item, index) => (
                    <SwiperSlide key={index}>
                        <Card key={index} item={item} amountCard={items.length} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};


const Card = ({ item, amountCard }: any) => {
    // const dateFormatted = moment(item.date).format('HH:mm');
    const addMessage = useMessageStore(state => state.addMessage);
    const HEIGHT_CARD = Number(process.env.REACT_APP_IMAGE_CARD_HEIGHT) || 158;

    const cleanedTitle = DOMPurify.sanitize(item.title);
    let cleanedSubtitle = DOMPurify.sanitize(item.subtitle);

    if (Number(process.env.REACT_APP_TEXT_CARD_LIMIT) && Number(process.env.REACT_APP_TEXT_CARD_LIMIT) > 120) {
        let count = Number(process.env.REACT_APP_TEXT_CARD_LIMIT);
        if (cleanedSubtitle.length > count) {
            cleanedSubtitle = cleanedSubtitle.substring(0, count - 3) + '...';
        }
    } else if (cleanedSubtitle.length > 120) {
        cleanedSubtitle = cleanedSubtitle.substring(0, 117) + '...';
    }

    const [hoverIndex, setHoverIndex] = useState(null);

    const handleMessage = async (button: Button) => {
        // title: string;
        // isLink: boolean;
        // shadowMessage: string;
        // value: string;
        if (button.isLink) {
            if (button.shadowMessage) {
                addMessage(button.shadowMessage, true)
            }
            if (button.value) {
                // await UtilGeneral.sleep(3000);
                const url = button.value.startsWith('http://') || button.value.startsWith('https://')
                    ? button.value
                    : `https://${button.value}`;
                window.open(url, '_blank');
            }
        } else {
            if (button.value) {
                addMessage(button.value, false);
            }

            if (button.shadowMessage) {
                addMessage(button.shadowMessage, true);
            }
        }
    }

    // Manejadores de eventos para mouse (convertir esto en MEMOS)
    // Memoizando la función handleMouseEnter
    const handleMouseEnter = useCallback((index: number) => {
        setHoverIndex(index);
    }, [setHoverIndex]); // Dependencias de useCallback

    // Memoizando la función handleMouseLeave
    const handleMouseLeave = useCallback(() => {
        setHoverIndex(null);
    }, [setHoverIndex]); // Dependencias de useCallback

    // min-w-[280px] max-w-[280px]
    // console.log(item)
    return (
        <div className={clsx("rounded-2xl bg-bubble w-[90%] max-w-[280px] min-h-[290.63px]", {
            "w-full": amountCard === 1,
        })}>

            <div className="">
                <MultimediaContent height={HEIGHT_CARD} className="rounded-t-2xl" fileType={item.fileType} src={item.url} />

                <div className='p-3'>
                    <p className="text-lg font-bold" dangerouslySetInnerHTML={{ __html: cleanedTitle }} />
                    <p className="text-sm text-gray-500" dangerouslySetInnerHTML={{ __html: cleanedSubtitle }} />

                    <div className="w-full flex flex-col items-center -mb-2">
                        {item?.buttons?.map((button: Button, index: number) => {
                            const backgroundColor = hoverIndex === index ? adjustColor("#708090", -20) : "#708090"

                            return (
                                <div
                                    onClick={() => handleMessage(button)}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                    style={{ backgroundColor: backgroundColor }}
                                    className={clsx(`flex justify-center w-[96%] rounded-md my-1 p-1.5`, {
                                        'bg-gray-300': !button.isLink,
                                    })}
                                    key={index}>
                                    <span
                                        className={clsx(`text-center w-full text-sm cursor-pointer`, {
                                            'flex justify-center items-center': button.isLink,
                                            'underline': hoverIndex === index && button.isLink,
                                        })}
                                        style={{ color: getTextColor(backgroundColor) }}>
                                        {button.title} {button.isLink && <FaExternalLinkAlt size={10} className="ml-1" />}
                                    </span>
                                </div>
                            )
                        }

                        )}
                    </div>
                </div>


            </div>
            {/* <div className="card-footer">
                <span>{dateFormatted}</span>
            </div> */}
        </div>
    );
};




export const BotCardMemo = React.memo(BotCard);
