export class UtilGeneral {

    static sleep = (milliseconds: number): Promise<void> => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    };

    static isValidUrl = (url: string) => {
        try {
            new URL(url);
            return true;
        } catch (e) {
            return false;
        }
    };
}