import { getTextColor } from "../../../utils/colors/get-text-color";
import { useConfigBotStore, useOpenChatStore } from "../../../store";
import { ButtonCloseBot } from "../button-close-bot/ButtonCloseBot";




interface FrameProps {
    children: React.ReactNode
}


export const Frame = ({ children }: FrameProps) => {
    const configBot = useConfigBotStore(state => state.configBot);
    const { setOpenChat, isOpenChat } = useOpenChatStore(state => state);

    return (
        <div
            //Se pone rounded-t-3xl para que no se vea el fondo blanco en el banner que es 2xl (div justo de abajo)
            className={`frame-box-shadow flex flex-col justify-end items-end bg-frameBot rounded-b-2xl w-full altura-custom iframenew:w-[375px] iframenew:h-[520px]`}
        >
            {children}
        </div>
    )
}
