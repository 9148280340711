import { create } from 'zustand';
import { BotMessage, CardMessage, QuickreplyMessage, TextMessage } from '../interfaces';
import { BotService } from '../services';
import { UtilGeneral } from '../utils/util-general';
import useOpenChatStore from './open-chat-store';
import Cookies from 'js-cookie';
import { KeepSafeResponse } from '../utils/keep-safe-response/keepSafeResponse';



type MessageStore = {
    messages: BotMessage[];
    systemUnavailableMessage: string;
    waitingForResponse: boolean;
    removeButtonsQuickreply: boolean;


    setSystemUnavailableMessage: (systemUnavailableMessage: string) => void;
    handleWaitingForResponse: (waitingForResponse: boolean) => void;
    /**
     * Si el botón es tipo link, nunca tendrá un valor que enviar, pero quizás sí un shadowMessage
     * 
     * Los shadowMessage son  valores: string que se envian al backend pero que no quedan grabados en el frame del bot.
     * Esto es necesario por si se quiere hacer alguna acción de guardado, activar un intent después de entrar en algún enlace, etc
     * 
     * 
     * Un botón que no sea link, siempre tendrá un valor que enviar, pero quizás no tenga un shadowMessage. En tal caso
     * el shadowMessage se enviaría después del valor.
     * @param message 
     * @param isShadowMessage 
     * @returns 
     */
    addMessage: (message: string, isShadowMessage?: boolean) => void;
    addRemoveButtonsQuickreply: () => void;
    clearMessages: () => void;

    addMessageFromFront: (messagesContent: any) => void;
};

// const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const useMessageStore = create<MessageStore>(
    (set, get) => ({
        messages: [],
        systemUnavailableMessage: undefined,


        waitingForResponse: false,
        removeButtonsQuickreply: false,
        handleWaitingForResponse: (waitingForResponse) => {
            set({ waitingForResponse });
        },
        addMessage: async (text: string, isShadowMessage: boolean = false) => {

            if (!text) return;

            const { messages, handleWaitingForResponse } = get();


            try {

                // Este extrato de código es necesario para cuando el bot es activado de nuevo, si había mensajes del bot
                // que estaban en proceso de ser enviados, parar la iteración y no mostrarlos
                // Más abajo lleva un return, en esa parte del código SI es necesario, aquí no.
                // if (Cookies.get('b.rbc.sm')) {
                //     Cookies.remove('b.rbc.sm');
                // }
                if (sessionStorage.getItem('sm')) {
                    sessionStorage.removeItem('sm');
                }






                handleWaitingForResponse(true);

                const mgsEntry = isShadowMessage ? undefined : new TextMessage('human', [text]);
                let updatedMessages = mgsEntry ? [...messages, mgsEntry] : [...messages];
                // Si ha habido un msgEntry lo mostramos ya en vez de esperar la respuesta para ver ambos mensajes
                if (mgsEntry) {
                    set({ messages: updatedMessages });
                }

                // Obtener respuestas del bot
                const result = await BotService.sendText(text);


                const { ok, item } = result || { ok: false, item: undefined };
                // const itemAlias = item;
                const itemAlias: any = KeepSafeResponse.decrypt(item);


                // Estos datos ya no se mandan al front porque no son necesarios
                // console.log("id flow::::::::", item?.flowId)
                // console.log("page::::::::", item?.pageName)
                // console.log("intent::::::::", item?.intentName)

                if (ok) {

                    await UtilGeneral.sleep(Number(process.env.REACT_APP_TIME_BETWEEN_MESSAGES_MS) || 0);

                    // for (const [i, fulfillment] of item.fulfillments.entries()) {
                    for (const [i, fulfillment] of itemAlias.fulfillments.entries()) {




                        // console.log(i, "comienzo")

                        // Este extrato de código es necesario para cuando el bot es activado de nuevo, si había mensajes del bot
                        // que estaban en proceso de ser enviados, parar la iteración y no mostrarlos
                        // if (sessionStorage.getItem('sm')) {
                        //     sessionStorage.removeItem('sm');
                        //     handleWaitingForResponse(false);
                        //     return;
                        // }

                        if (sessionStorage.getItem('sm')) {
                            sessionStorage.removeItem('sm');
                            handleWaitingForResponse(false);
                            return;
                        }

                        // if (Cookies.get('b.rbc.sm')) {
                        //     Cookies.remove('b.rbc.sm');
                        //     handleWaitingForResponse(false);
                        //     return;
                        // }


                        let fullfillmentMessage;
                        switch (fulfillment.message) {
                            case "text":
                                fullfillmentMessage = new TextMessage('bot', fulfillment.text);
                                break;
                            case "quickreply":
                                fullfillmentMessage = new QuickreplyMessage('bot', fulfillment);
                                break;
                            case "card":
                                fullfillmentMessage = new CardMessage('bot', fulfillment);
                                break;
                            default:
                                break;
                        }
                        if (fullfillmentMessage) {
                            updatedMessages.push(fullfillmentMessage);
                            set({ messages: updatedMessages });
                        }

                        // if (item.fulfillments.length - 1 === i) {
                        if (itemAlias.fulfillments.length - 1 === i) {


                            handleWaitingForResponse(false);
                        } else {

                            // console.log("fulfillment", fulfillment)
                            // console.log(i, "entro aqui?")
                            // console.log("Esperando respuesta del bot", process.env.REACT_APP_TIME_BETWEEN_MESSAGES_MS)
                            // console.log("Esperando respuesta del bot 2", Number(process.env.REACT_APP_TIME_BETWEEN_MESSAGES_MS))
                            // handleWaitingForResponse(false);
                            // await UtilGeneral.sleep(Number(process.env.REACT_APP_DELAY_TO_SHOW_DOT_MS) || 0);
                            // handleWaitingForResponse(true);
                            // await UtilGeneral.sleep(Number(process.env.REACT_APP_TIME_BETWEEN_MESSAGES_MS) || 0);


                            let DELAY = 0;
                            if (fulfillment && typeof fulfillment?.delay === 'number') {
                                DELAY = fulfillment?.delay;
                            } else if (process.env.REACT_APP_TIME_BETWEEN_MESSAGES_MS) {
                                DELAY = Number(process.env.REACT_APP_TIME_BETWEEN_MESSAGES_MS) || 0;
                            }

                            handleWaitingForResponse(false);
                            // Usa delay en lugar del valor del .env si está disponible
                            await UtilGeneral.sleep(Number(process.env.REACT_APP_DELAY_TO_SHOW_DOT_MS) || 0);
                            handleWaitingForResponse(true);
                            await UtilGeneral.sleep(DELAY);


                        }
                    }
                } else {
                    // const { messages } = get();
                    // set({ messages: [...messages, mgsEntry], waitingForResponse: false })
                }
                handleWaitingForResponse(false);
            } catch (error) {
                console.log(error);
                // set({ messages: [], waitingForResponse: false })
                set({ waitingForResponse: false })

            }
        },
        addRemoveButtonsQuickreply: () => {
            set({ removeButtonsQuickreply: true });
            setTimeout(() => {
                set({ removeButtonsQuickreply: false });
            }, 100);
        },
        clearMessages: () => {
            const { messages } = get();
            set({ messages: [] })

        },
        addMessageFromFront: (messagesContent) => {
            const { messages } = get();

            try {


                let list: any[] = JSON.parse(messagesContent);

                if (!Array.isArray(list)) {
                    throw new Error('Message front bot');
                }

                const newMessages = list.map((item, index) => {
                    let message = item.message
                    switch (message) {
                        case "text":
                            return new TextMessage('bot', item.text);
                        case "quickreply":
                            return new QuickreplyMessage('bot', item);
                        case "card":
                            return new CardMessage('bot', item);
                        default:
                            throw new Error('Message front bot');
                    }
                }).filter(message => message !== null);

                set({ messages: [...messages, ...newMessages] });


            } catch (error) {
                throw new Error('Message front bot');
            }
        },
        setSystemUnavailableMessage: (systemUnavailableMessage) => {
            set({ systemUnavailableMessage });
        },

    }));

export default useMessageStore;
