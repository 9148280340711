import { create } from 'zustand';
import { BotService } from '../services';

type OpenChatStore = {
    isOpenChat: boolean;
    setOpenChat: (isOpenChat: boolean) => void;
};


const useOpenChatStore = create<OpenChatStore>(
    (set, get) => ({
        isOpenChat: false,
        setOpenChat: async (value) => {

            set({ isOpenChat: value });

            if (!value) {
                // Este evento se encarga de mandar a final de la sessión en Dialogflow CX
                const result = await BotService.sendEvent('RBC Clean Session');
                console.log("Evento Final session", result);
            };
        },
    }));

export default useOpenChatStore;
