export const getTextColor = (hexColor: string) => {

    if (!hexColor) return '#000000';
    // Primero, convertir el hexColor a RGB
    const r = parseInt(hexColor.substring(1, 3), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);

    // Calcular la luminosidad
    const luminosidad = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    const umbral = 150;

    return luminosidad > umbral ? '#000000' : '#FFFFFF';
}


