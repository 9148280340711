import { BotService } from "../../services";
import { useMessageStore } from "../../store";
import { ExclusionStrategy } from "./interfaces/ExclusionStrategy.interface";

export class BannedUserStrategy implements ExclusionStrategy {
    private systemUnavailableMessage: string;
    constructor(systemUnavailableMessage?: string) {
        this.systemUnavailableMessage = systemUnavailableMessage;
    }

    async handle(code: string): Promise<void> {
        try {
            const { systemUnavailableMessage: systemUnavailableMessageStore, setSystemUnavailableMessage, addMessageFromFront } = useMessageStore.getState();
            console.log(`Manejando código de BAN: ${code}`);
            setSystemUnavailableMessage(this.systemUnavailableMessage);

            let message = this.systemUnavailableMessage || systemUnavailableMessageStore;
            // Si ya existe un mensaje de sistema no disponible, úsalo.
            if (message) {
                this.addSystemMessageToFront(message, addMessageFromFront);
            }
        } catch (error) {
            console.error(error);
        }
    }

    private addSystemMessageToFront(message: string, addMessageFromFront: Function) {
        const text = {
            text: [message],
            message: "text"
        };
        addMessageFromFront(JSON.stringify([text]));
    }
}
