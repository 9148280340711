import { useEffect, useRef, useState } from 'react'
import { Frame } from '../../components/ui/frame/Frame'
import { Banner, BotTypping, ChatFAB, Keyboard, LabelPoweredBy } from '../../components'
import { AuthService } from '../../services/auth/auth.service'
import { BotMessageSelector } from '../../components/payloads/bot-message-selector/BotMessageSelector'
import {
    useBotActivationBot,
    useConfigBotStore,
    useMessageStore,
    useOpenChatStore
}
    from '../../store'
import { useSearchParams } from 'react-router-dom'
import { parseQueryParams } from '../../utils/parse-query-params'
import { useStoreHasWelcome } from '../../hooks'
import { CONFIGBOT_INIT_DEVELOPMENT_V1, CONFIGBOT_INIT_DEVELOPMENT_V2 } from '../../interfaces'
import clsx from 'clsx'
import { updateGradientColor } from '../../utils/colors/color-crystal'
import { getContrastColor } from '../../utils/colors/adjust-color'
import { UtilGeneral } from '../../utils/util-general'
import { animated, useSpring } from '@react-spring/web'
import Cookies from 'js-cookie'





export const Bot = () => {

    // let params = useParams();
    const [searchParams] = useSearchParams();


    const { messages, waitingForResponse, addMessage, addMessageFromFront, clearMessages } = useMessageStore(state => state);
    const { configBot, addConfigBot } = useConfigBotStore(state => state);
    const { isOpenChat } = useOpenChatStore(state => state);
    const { origin, setOrigin } = useBotActivationBot(state => state);

    const isOpenChatRef = useRef(isOpenChat);

    const { hasWelcomeHidden } = useStoreHasWelcome();

    const [animate, setAnimate] = useState(false);

    // const [pageAccessBot, setPageAccessBot] = useState<string | undefined>(undefined);



    useEffect(() => {
        let style = configBot?.style;
        switch (style) {
            case 'crystal':
                updateGradientColor(configBot?.color1);
                document.documentElement.style.setProperty('--scrollbar-thumb-color', `${configBot?.color1}`);
                document.documentElement.style.setProperty('--scrollbar-track-color', `transparent`);
                break;
            case 'basic':
                document.documentElement.style.setProperty('--scrollbar-thumb-color', `${getContrastColor(configBot?.color3, 20)}`);
                document.documentElement.style.setProperty('--scrollbar-track-color', `#ffffff`);
                break;
            default:
                break;
        }



    }, [configBot])



    // document.documentElement.style.setProperty('--scrollbar-track-color', `${configBot?.color1}`);

    // Referencia al último mensaje para hacer scroll
    const endOfMessagesRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const isDevelopment = process.env.NODE_ENV === 'development';
        const hasWelcomeStored = sessionStorage.getItem('welcome-hidden');

        let params = parseQueryParams(searchParams);

        if (params?.environment === "test-bot") {
            sessionStorage.removeItem('welcome-hidden');
        }


        console.log(params);
        console.log(params);
        console.log(params);
        console.log(params);

        console.log("isDeve", isDevelopment)


        const origen = params?.iframeUrl;
        // Se borra porque no es una variable de ConfigBot
        delete params?.iframeUrl;
        /**
         * Verifica si existen parámetros y si el entorno de desarrollo no es "development". 
         * Si ambas condiciones se cumplen, se ejecuta el bloque de código dentro del condicional.
         */
        if (params && (Object.keys(params).length && !isDevelopment)) {

            let cofg = params;
            if (!cofg?.logo) {
                cofg.logo = "logo_robot_default.jpg"
            } else if (cofg.logo && !UtilGeneral.isValidUrl(cofg.logo)) {
                console.log("vamos a logo 2");

                cofg.logo = process.env.REACT_APP_API_URL + cofg.logo;
                // cofg.logo = "https://bot-secure-node.redbluecode.com" + cofg.logo;

                if (!UtilGeneral.isValidUrl(cofg.logo)) {
                    cofg.logo = "logo_robot_default.jpg"
                };
            };
     
            if (hasWelcomeStored) params.welcomeMessage = undefined;
            addConfigBot(params);

        } else if (isDevelopment) {
            let config = configBot?.style === "basic" || !configBot?.style ? CONFIGBOT_INIT_DEVELOPMENT_V1 : CONFIGBOT_INIT_DEVELOPMENT_V2;
            if (hasWelcomeStored) config.welcomeMessage = undefined;

            let cofg = params && (Object.keys(params).length) ? params : config;

            if (!cofg?.logo) {
                cofg.logo = "logo_robot_default.jpg"
            } else if (cofg.logo && !UtilGeneral.isValidUrl(cofg.logo)) {
                console.log("vamos a logo 2");

                cofg.logo = process.env.REACT_APP_API_URL + cofg.logo;
                // cofg.logo = "https://bot-secure-node.redbluecode.com" + cofg.logo;

                if (!UtilGeneral.isValidUrl(cofg.logo)) {
                    cofg.logo = "logo_robot_default.jpg"
                };
            };
            addConfigBot(cofg);
        }


        if (origen && UtilGeneral.isValidUrl(origen)) {
            console.log("Estoy en bot, Sí hay origen", origen)
            setOrigin(origen);
        } else if (isDevelopment) {
            console.log("Estoy en bot, No hay origen")
            setOrigin(window.location.origin);
        } else {
            console.log(origen)
            console.error("No se se ha podido determinar el origen");
        }

    }, [searchParams])




    // useEffect(() => {
    //     // Si ya hay 20 mensajes, no hacer nada
    //     if (messages.length >= 20) {
    //         return;
    //     }

    //     // Configurar un intervalo para agregar un nuevo mensaje cada 3 segundos
    //     const intervalId = setInterval(() => {
    //         setMessages(prevMessages => [
    //             ...prevMessages,
    //             new TextMessage(['bot', 'human'][Math.floor(Math.random() * 2)] as any, ['Hola', 'Adios', 'lorem que tal escribe un mensaje largo por favor que me aburro tres cuatro cinco hay rima ya sabes']).getMessage()!
    //         ]);

    //         setTimeout(() => {
    //             if (messages.length) {
    //                 // Hacer scroll al último mensaje
    //                 endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    //             }
    //         }, 0)
    //     }, 400);

    //     // Limpiar el intervalo cuando el componente se desmonte o cuando tengamos 20 mensajes
    //     return () => clearInterval(intervalId);
    // }, [messages]); // Dependencia del efecto: la lista de mensajes


    useEffect(() => {

        console.log(messages)
        if (messages.length > 0) {
            console.log("si")
            setTimeout(() => {
                console.log("set time")
                // Hacer scroll al último mensaje
                endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    }, [messages && messages.length]);


    useEffect(() => {
        if (origin && isOpenChat) {

            if (messages && messages.length > 0) clearMessages();

            const fetchAuthCredentials = async () => {
                try {
                    const data = await AuthService.access();
                    // Cookies.set('miToken', data.item, { expires: 1 / 24 });
                    console.log(data);
                    if (data && data.ok) {
                        initBot();
                    }
                } catch (error) {
                    console.error('Error al obtener las credenciales de autenticación:', error);
                }
            };

            console.log(configBot?.environment)

            if (configBot?.environment === 'test-bot') {
                console.log("Test mode")
                initBotTEST();
            } else {
                console.log("Normal mode")
                // Llamada a la función asíncrona
                fetchAuthCredentials();
            }


        }
    }, [origin, isOpenChat])


    const initBot = async () => {
        try {
            const messageInitFromFront = process.env.REACT_APP_MESSAGE_WELCOME;

            setTimeout(() => {
                if (messageInitFromFront) {
                    addMessageFromFront(messageInitFromFront);
                } else {
                    createMessageInitToBackend();
                }
            }, 200);

        } catch (error) {
            console.log(error)
            if (error instanceof Error && error.message === 'Message front bot') {
                console.warn("Mandando mensaje tras error");
                createMessageInitToBackend();
            } else {
                console.error('Error al iniciar el bot:', error);
            }

        }
    }


    /**
     * Cuando está en modo test solamente es muestra este mensaje
     */
    const initBotTEST = async () => {
        try {
            const messageInitFromFront = process.env.REACT_APP_TEST_MODE_MESSAGE;
            if (messageInitFromFront) {
                addMessageFromFront(messageInitFromFront)
            }
        } catch (error) {
            if (error instanceof Error && error.message === 'Message front bot') {
                console.warn("Mandando mensaje tras error");
            } else {
                console.error('Error al iniciar el bot:', error);
            }

        }
    }

    /**
     * Crea mensaje de inicio para el bot usando el backend
     */
    const createMessageInitToBackend = async () => {
        try {
            const isShadowMessage = true;
            const data = await addMessage('000RBCStart', isShadowMessage);
            console.warn("Mensaje para iniciar el bot enviado", data);
        } catch (error) {
            throw error;
        }
    }


    const messageContainerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (!messageContainerRef.current) return;

            const { top, bottom, height } = messageContainerRef.current.getBoundingClientRect();
            // Código para ajustar la posición del avatar aquí
            // Por ejemplo, podrías cambiar el 'top' del avatar usando estado o manipulación directa del DOM
        };

        // Añadir listener de scroll
        window.addEventListener('scroll', handleScroll);

        return () => {
            // Limpiar el listener
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const animationProps = useSpring({
        from: { opacity: 0, transform: 'scale(0)', transformOrigin: 'bottom right' },
        to: { opacity: 1, transform: 'scale(1)' },
        config: { duration: 200 },
        reset: animate,  // Forzar la animación cada vez que el estado cambie
        onRest: () => {
            setAnimate(false)
        } // Resetear el estado después de la animación
    });

    useEffect(() => {
        console.log("dentro de useEffect 1")
        if (isOpenChat) {
            console.log("dentro de isOpenChat 2")
            setAnimate(true);
        } else {
            // Este extrato de código es necesario para cuando el bot es activado de nuevo, si había mensajes del bot
            // que estaban en proceso de ser enviados, parar la iteración y no mostrarlos
            // sm = stop-message
            // xKjg9a = no significa nada, literal. Es solo para que haya un valor.
            sessionStorage.setItem('sm', "xKjg9a");
            // const in30Seconds = new Date(new Date().getTime() + 30 * 1000);
            // Cookies.set('b.rbc.sm', "xKjg9a", {
            //     expires: in30Seconds,
            //     secure: true,
            //     sameSite: 'Strict',
            //     path: '/'
            // });
        }

    }, [isOpenChat]);


    if (!configBot) return null;

    if (!isOpenChat) {
        return (
            <ChatFAB />
        )

    }

    return (

        <animated.div
            style={animationProps}
            className={clsx(`scale-0 opacity-0 absolute w-full right-0 bottom-0 iframenew:bottom-5 iframenew:right-5 iframenew:w-[375px]`,
                configBot.style === 'crystal' ? 'glassmorphism-body rounded-2xl' : "",
            )}
        >


            <Banner />

            <Frame>
                <div ref={messageContainerRef} 
                className={clsx(`custom-scrollbar overflow-x-hidden flex flex-col-reverse overflow-y-auto w-full altura-custom iframenew:h-[520px] iframenew:w-[375px]`,
                    // configBot.style === 'crystal' ? 'glassmorphism-body' : "",
                )}>


                    <div className="flex flex-col max-w-[375px]">
                        {messages.map((message, i) => (
                            // Usamos React.memo para evitar que se renderice de nuevo el componente
                            // Memo es una optimización para componentes funcionales
                            // https://es.react.dev/reference/react/memo
                            // <BotTextMemo key={i} message={message as TextMessage} />

                            <BotMessageSelector key={i} message={message} />

                        ))}
                        {waitingForResponse && <BotTypping />}

                        <div ref={endOfMessagesRef} />


                        <div className='mt-auto mb-8'>
                            <LabelPoweredBy />
                        </div>
                    </div>
                </div>


                {/* Pensar en quitar el iframenew:bottom-5. Pone el keyboard too high */}
                <div className="fixed botton-0 w-full iframenew:w-[375px] z-50">
                    <Keyboard disabled={configBot?.environment === "test-bot"} />
                </div>

                {/* Para que los mensajes no se vean poir debajo */}
                <div className='h-[30px] bg-transparent w-full' />
            </Frame>


        </animated.div>
    );
}

