import './App.css';
import { Bot } from './pages/bot/Bot';

// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HashRouter as Router, Routes, Route, } from 'react-router-dom';



// Interceptor 
import "./services/interceptors/origin/origin-interceptor.service";
import "./services/interceptors/exclusion-code/exclusion-code.service";


// TODO: Quita console.log en production
if (process.env.NODE_ENV === 'production') {
  console.log = function () { };
}


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/bot" element={<Bot />} />
      </Routes>
    </Router>
  );
}

export default App;
