import axios from "axios";
import { AllowedWebStrategy, BannedUserStrategy, ExclusionStrategy, MessageQuotaStrategy, TooManyMessagesIp, BlockedBot } from "../../../interfaces";

export class ExclusionCodeService {
    private strategy: ExclusionStrategy;

    constructor(strategy: ExclusionStrategy) {
        this.strategy = strategy;
    }

    handleCode(code: string) {
        this.strategy.handle(code);
    }

    setStrategy(strategy: ExclusionStrategy) {
        this.strategy = strategy;
    }
}

export class ExclusionStrategyFactory {
    static getStrategy(code: string, systemUnavailableMessage: string): ExclusionStrategy | null {
        switch (code) {
            case "001":
                return new BannedUserStrategy(systemUnavailableMessage);
            case "002":
                return new AllowedWebStrategy(systemUnavailableMessage);
            case "003":
                return new MessageQuotaStrategy(systemUnavailableMessage);
            case "004":
                return new TooManyMessagesIp(systemUnavailableMessage);
            case "005":
                return new BlockedBot(systemUnavailableMessage);
            default:
                return null;
        }
    }
}


axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {

        if (error.response
            && error.response.data
            && error.response.data.item
            && error.response.data.message === "Exclusión de servicios"
            && error.response.data.item.code) {

            const code = error.response.data.item.code;
            const systemUnavailableMessage = error.response.data.item.systemUnavailableMessage
            const strategy = ExclusionStrategyFactory.getStrategy(code, systemUnavailableMessage);

            if (strategy) {
                strategy.handle(code);
            } else {
                console.warn(`No se encontró una estrategia de exclusión para el código: ${code}`);
            }

        } else {
            console.error("Error desde interceptor de respuesta");
            console.error(error);
        }
        return Promise.reject(error);
    }
);