import axios from "axios";


const apiUrl = process.env.REACT_APP_API_URL;


export class BotService {

    static sendText(text: string, environmentDialogflow: string = "") {

        return axios.post(`${apiUrl}/api/web/bot-text`, { text, environmentDialogflow }, { withCredentials: true })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    static sendEvent(event: string, environmentDialogflow: string = "") {

        return axios.post(`${apiUrl}/api/web/bot-event`, { event, environmentDialogflow }, { withCredentials: true })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }


    static getFallbackMessage() {
        return axios.get(`${apiUrl}/api/configuration/fallback-message`, { withCredentials: true })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }


}