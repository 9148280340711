export class BotMessage {
    message?: MessageType;
    userType: UserType;
    date: Date;

    constructor(userType: 'bot' | 'human', message?: MessageType) {
        this.userType = userType;
        this.date = new Date();
        this.message = message;
    }

    /** Construye y devuelve */
    getMessage(): BotMessage | undefined { return undefined }
}


export type MessageType = 'text' | 'card' | 'quickreply';
export type UserType = 'bot' | 'human';
export type FileType = 'image-cloud' | 'image-local' | 'video-cloud' | 'video-local';
