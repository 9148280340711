import { useConfigBotStore } from '../../../store';
import { getTextColor } from '../../../utils/colors/get-text-color';
import { ButtonCloseBot } from '../button-close-bot/ButtonCloseBot';

export const Banner = () => {
    const configBot = useConfigBotStore(state => state.configBot);

    return (
        <div className="flex flex-grow items-center w-full  bg-slate-400 h-[200px] max-h-[80px] rounded-t-2xl"
            style={{ backgroundColor: configBot?.color2, }}
        >
            <span className="flex items-center justify-center mx-5 rounded-full bg-gray-950 w-[45px] h-[45px] overflow-hidden">
                <img src={configBot.logo} alt="logo_bot" className="w-full h-full object-cover" />
            </span>

            <div className="flex flex-col">
                <span className="font-bold text-lg"
                    style={{ fontWeight: 400, color: getTextColor(configBot.color1) }}>{configBot.name}</span>
                {/* <span className="text-sm truncate ...">Mensaje secundario</span> */}
            </div>

            <ButtonCloseBot />
        </div>
    )
}

