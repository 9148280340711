import React from "react";
import { TextMessage } from "../../../interfaces";
import { WrapperMessage } from "../../ui/wrapper-message/WrapperMessage";
import moment from "moment";
import clsx from "clsx";
import useConfigBotStore from "../../../store/config-bot-store";
import { getTextColor } from "../../../utils/colors/get-text-color";
import * as DOMPurify from 'dompurify';
import { LoadingDots } from "../../ui/loading-dots/LoadingDots";
import { WrapperMessage2 } from "../../ui/wrapper-message/WrapperMessage2";


export const BotTypping = () => {


    return (
        <WrapperMessage2 userType={"bot"}>
            <LoadingDots />
        </WrapperMessage2 >
    );
}


export const BotTextMemo = React.memo(BotTypping);
