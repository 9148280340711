// MultimediaContent.tsx
import React, { CSSProperties } from 'react';
import { FileType } from '../../../interfaces';

interface MultimediaContentProps {
    fileType: FileType;
    src: string;

    className?: string;
    height?: number;
}

export const MultimediaContent = ({ fileType, src, className, height }: MultimediaContentProps) => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const commonStyles: CSSProperties = {
        width: '100%',
        height: height ? `${height}px` : 'auto',
        objectFit: 'cover',
    };

    const noMultimedia = !fileType || !src;
    const isImage = fileType?.includes('image');
    const isLocal = fileType?.includes('local');
    const isYouTube = fileType?.includes('video-cloud') && src?.includes('youtube');
    const labelLocal = isImage ? 'images' : 'videos';
    const basePath = isLocal ? `${apiUrl}/uploads/${labelLocal}/` : '';

    console.log("mira que es basePath", basePath, src, fileType)
    const handleError = (event: any) => {
        console.error('Error al cargar el contenido multimedia');
        // Define la URL de tu imagen de respaldo
        const backupImageUrl = './dalle2_detective_notfound.png';

        if (isImage) {
            // Cambia la fuente de la imagen
            event.target.src = backupImageUrl;
        } else {
            // Para videos, puedes mostrar una imagen de respaldo o manejarlo de otra manera
            // Aquí se asume que quieres reemplazar el video por una imagen
            const backupImage = document.createElement('img');
            backupImage.src = backupImageUrl;
            Object.assign(backupImage.style, commonStyles);
            backupImage.className = event.target.className;
            event.target.replaceWith(backupImage);
        }

    };

    if (noMultimedia) {
        return null;
    }

    // console.log("mira esto", basePath, src, fileType)

    const videoIframe = (embedUrl: string) => (
        <div className="aspect-w-16 aspect-h-9">
            <iframe
                src={embedUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full h-full rounded-t-lg"
                style={commonStyles}
            ></iframe>
        </div>
    );

    const renderContent = () => {
        if (isImage) {
            return <img style={commonStyles} className={`${className}`} src={`${basePath}${src}`} alt="Contenido multimedia" onError={(event) => handleError(event)} />;
        } else if (isYouTube) {
            const embedUrl = src.replace('watch?v=', 'embed/');
            return videoIframe(embedUrl);
        } else {
            return <video className="rounded-t-lg" style={commonStyles} src={`${basePath}${src}`} controls onError={handleError} />;
        }
    };

    return <div>{renderContent()}</div>;
};


