import { BotMessage, FileType, MessageType, UserType } from "./bot-message.interface";
import { Button } from "./button.interface";


export class CardMessage extends BotMessage {

    items: Item[];

    constructor(userType: UserType = 'bot', { items }: CardMessage) {
        super(userType);
        this.message = 'card';
        this.items = items;
    }

    getMessage(): BotMessage | undefined {
        return this;
    }
}

export interface Item {
    title: string;
    buttons: Button[];
    subtitle: string;
    fileType: FileType;
    url: string;
}

