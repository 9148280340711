// src/axiosConfig.js
import axios from 'axios';
import { useBotActivationBot } from '../../../store';


axios.interceptors.request.use(
    config => {
        /**
         * Introduce el origen de donde se llama el bot para mandarlo al backend
         * Si se usa en DEV, puede mandar el origen del iframe o el del front del bot, según
         * donde estés usándolo.
         */
        const origin = useBotActivationBot.getState()?.origin;
        config.headers['X-Origin-Activation'] = origin;
        return config;
    },
    error => {
        console.error("Error desde interceptor");
        console.error(error);
        return Promise.reject(error);
    }
);


