import { SlArrowDown } from "react-icons/sl";
import { useConfigBotStore, useMessageStore, useOpenChatStore } from "../../../store";
import { getContrastColor } from "../../../utils/colors/adjust-color"
import { getTextColor } from "../../../utils/colors/get-text-color";
import { useEffect, useState } from "react";
import { sendMessageToIframe } from "../../../utils/sendMessageToIframe";
import { useStoreHasWelcome } from "../../../hooks";


export const ButtonCloseBot = () => {

    const { hasWelcomeHidden } = useStoreHasWelcome();


    const configBot = useConfigBotStore(state => state.configBot);
    const { isOpenChat, setOpenChat } = useOpenChatStore(state => state);
    const { clearMessages } = useMessageStore(state => state);


    const [isHover, setIsHover] = useState<boolean>(false);


    const handleMouseEnter = () => setIsHover(true);
    const handleMouseLeave = () => setIsHover(false);

    const handleOpenChat = () => {
        if (!isOpenChat) return;
        const hasWelcomeStored = sessionStorage.getItem('welcome-hidden');

        setOpenChat(false);
        clearMessages();

        sendMessageToIframe({
            action: 'rbc-open-chat', payload: {
                isOpen: false,
                messageType: hasWelcomeStored ? false : configBot.welcomeMessage?.type,
            }
        });

    }

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="absolute right-4 w-[33px] h-[33px] rounded-full flex justify-center items-center cursor-pointer"
            style={{ backgroundColor: isHover ? getContrastColor(configBot.color1, 40) : getContrastColor(configBot.color1) }}
            onClick={handleOpenChat}>

            <SlArrowDown size={11} color={getTextColor(configBot.color1)} className="antialiased" />
        </div>
    )
}

