// util/parseQueryParams.js

/**
 * Parsea los parámetros de búsqueda de una URL y decodifica aquellos que están en formato JSON.
 * @param {URLSearchParams} searchParams - Los parámetros de búsqueda de la URL.
 * @returns {Object} - Un objeto con los parámetros de búsqueda parseados.
 */
export function parseQueryParams(searchParams: URLSearchParams) {
    const params: any = {};
    for (const [key, value] of searchParams.entries()) {
      try {
        // Intentamos parsear el valor JSON. Si no es JSON, lo dejamos como está.
        params[key] = JSON.parse(decodeURIComponent(value));
      } catch (error) {
        // Si no es JSON o si hay algún error, usamos el valor decodificado.
        params[key] = decodeURIComponent(value);
      }
    }
    return params;
  }
  