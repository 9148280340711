export const LabelPoweredBy = () => {
    return (
        <div style={{ fontSize: 11, alignItems: 'center' }} className="relative flex justify-center items-center text-gray-600 font-bold p-2 w-full h-8 ">
            <span className="no-select absolute top-[10.5px] right-1/2  text-start" style={{ fontSize: 10 }}>POWERED BY&nbsp;</span>
            <img className="no-select absolute left-1/2 grayscale" src="logo-color.png" alt="Logo" width={90} />
        </div>
        // <span>RedBlueCode</span> 
    )
}

