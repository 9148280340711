import React, { useState } from "react";
import { QuickreplyMessage } from "../../../interfaces";
import { WrapperMessage } from "../../ui/wrapper-message/WrapperMessage";
import { MultimediaContent } from "../..";
import moment from "moment";
import clsx from "clsx";
import DOMPurify from "dompurify";
import { ButtonsMessage2 } from "../../ui/buttons-message_2/ButtonsMessage2";



import { WrapperMessage2 } from "../../ui/wrapper-message/WrapperMessage2";

interface BotQuickreplyProps {
    message: QuickreplyMessage;
}


export const BotQuickreply = ({ message }: BotQuickreplyProps) => {
   
    const MAX_CHARACTERS_FOR_SINGLE_LINE = 36;

    const randomIndex = Math.floor(Math.random() * message.title.length);
    const selectedText = message.title[randomIndex];
    const cleanedText = DOMPurify.sanitize(selectedText);

    const dateFormatted = moment(message.date).format('HH:mm');

    const isShortText = selectedText.length <= MAX_CHARACTERS_FOR_SINGLE_LINE;

    return (
        <>
            <WrapperMessage2
                userType={message.userType}
                buttons={message.buttons}
                date={undefined}
                textLength={0}
            >
                <div className={clsx("flex", {
                    "flex-row items-center justify-between": isShortText && !message.url, // Si el texto es corto, usa flex-row
                    "flex-col": !isShortText || message.url  // Si el texto es largo, usa flex-col
                })}>
                    {/* Contenido principal */}
                    <div className="w-full">
                        <span className="text-black text-sm mb-3" style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: cleanedText }} />
                        <MultimediaContent fileType={message.fileType} src={message.url} />
                    </div>
                    {/* Fecha formateada */}
                    {isShortText ? (
                        <span className=" ml-1 self-end" style={{ fontWeight: 600, fontSize: 11 }}>{dateFormatted}</span>
                    ) : (
                        <div className="w-full text-right">
                            <span className=" ml-1 self-end" style={{ fontWeight: 600, fontSize: 11 }}>{dateFormatted}</span>
                        </div>
                    )}
                </div>
            </WrapperMessage2>

            {
                message.userType === 'bot' && (
                    <div className='w-full flex px-3'>
                        <ButtonsMessage2 buttons={message.buttons ?? []} />
                    </div>
                )
            }
        </>

    )
}

export const BotQuickreplyMemo = React.memo(BotQuickreply);


