import { useEffect, useRef, useState } from "react";
import useConfigBotStore from "../../../../store/config-bot-store";
import { useSpring, animated } from '@react-spring/web';
import { RxCross2 } from "react-icons/rx";
import clsx from "clsx";
import { getTextColor } from "../../../../utils/colors/get-text-color";
import { adjustColor, getContrastColor } from "../../../../utils/colors/adjust-color";
import { ConfigBot } from "../../../../interfaces";
import { useStoreHasWelcome } from "../../../../hooks";
import { sendMessageToIframe } from "../../../../utils/sendMessageToIframe";


interface WelcomeSlideTypeProps {
  configBot: ConfigBot;
}

export const WelcomeSlideType = ({ configBot }: WelcomeSlideTypeProps) => {

  const { storeWelcomeHidden } = useStoreHasWelcome();


  const [messageWelcome, setMessageWelcome] = useState("");
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isOpenMessage, setIsOpenMessage] = useState(false);

  const LIMIT_CHARACTERS = 28;


  const messageRef = useRef(null);
  const [messageWidth, setMessageWidth] = useState(0);
  const WIDTH_MINIMUN = 90;

  const [animationProps, setAnimationProps] = useState({
    width: '0px',
    opacity: 0,
  });



  // Usa el ancho estimado en tu animación.
  // const props = useSpring({
  //   width: isOpenMessage ? `${messageWidth + WIDTH_MINIMUN}px` : '0px', // Limita el ancho a 300px o menos
  //   opacity: isOpenMessage ? 1 : 0,
  //   overflow: 'hidden',
  // });



  // Manejadores de eventos para mouse
  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);

  useEffect(() => {
    let timer: any = undefined;
    const hasWelcomeStored = sessionStorage.getItem('welcome-hidden');

    if (configBot.welcomeMessage && !hasWelcomeStored) {
      const messageWelcome = LIMIT_CHARACTERS > configBot.welcomeMessage?.text?.length
        ? configBot.welcomeMessage?.text
        : configBot.welcomeMessage.text.slice(0, LIMIT_CHARACTERS) + "...";

      setMessageWelcome(messageWelcome);
      // Damos anchos estimados para que la animación de apertura se vea bien
      // setEstimatedWidth(messageWelcome.length * estimatedWidthPerCharacter + estimatedPadding + 50);
      // Si quieres que el mensaje de bienvenida se muestre solo después de un retraso
      timer = setTimeout(() => {
        setIsOpenMessage(true);
      }, configBot.welcomeMessage.delay || 100);
    }

    return () => timer ? clearTimeout(timer) : null;

  }, [configBot.welcomeMessage]);




  // Siempre inicia
  useEffect(() => {

      if (messageRef.current) {
        const scrollWidth = messageRef.current.scrollWidth;
        // console.log("mira que es scrollWidth", scrollWidth)

        setMessageWidth(scrollWidth);
        const hasWelcomeStored = sessionStorage.getItem('welcome-hidden');


        sendMessageToIframe({
          action: 'rbc-open-chat', payload: {
            isOpen: false,
            messageType: hasWelcomeStored ? false : configBot.welcomeMessage?.type,
            size: hasWelcomeStored ? null : {
              height: 0,
              width: scrollWidth + WIDTH_MINIMUN // Tengo que poner el calculo del texto
            }
          }
        });
      }
 
  }, [messageWelcome])

  useEffect(() => {
    // Después de que se haya calculado el ancho estimado, actualiza las propiedades de la animación
    if (messageWidth && isOpenMessage) {
      setAnimationProps({
        width: `${messageWidth + 35}px`,
        opacity: 1,
      });
    } else {
      setAnimationProps({
        width: '0px',
        opacity: 0,
      });
    }
  }, [messageWidth, isOpenMessage]); // Dependencias para recalcular cuando cambian

  // Define la animación con las propiedades actualizadas
  const props = useSpring(animationProps);


  // Función para manejar el cierre del mensaje de bienvenida
  const handleCloseMessage = () => {
    setIsOpenMessage(false); // Esto activará la animación de cierre
    storeWelcomeHidden();

    sendMessageToIframe({
      action: 'rbc-open-chat', payload: {
        isOpen: false,
        messageType: null,
        size: null
      }
    });
  };

  return (
    <animated.div ref={messageRef}
      style={{
        ...props,
        ...{ backgroundColor: configBot.color3 }
      }}
      className={clsx(`flex items-center h-[60px] text-white rounded-l-lg overflow-hidden shadow-lg absolute right-8`)}
    >
      <div
        onClick={handleCloseMessage}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="flex items-center justify-center h-full cursor-pointer"
        style={{ backgroundColor: isHover ? getContrastColor(configBot.color3) : null }}
      >
        <RxCross2 size={18} className="text-white mx-1" style={{ color: getTextColor(configBot.color3) }} />
        <div className="w-px h-[85%]" style={{ backgroundColor: getTextColor(configBot.color3) }} />
      </div>
      <p className="ml-2 whitespace-nowrap text-sm" style={{ color: getTextColor(configBot.color3) }}>{messageWelcome}</p>
    </animated.div>
  )
}
