import axios from "axios";
import Cookies from "js-cookie";

const apiUrl = process.env.REACT_APP_API_URL;

export class AuthService {
    constructor() { }

    static access() {
     
        return axios.get(`${apiUrl}/api/auth`, { withCredentials: true })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // Modo test
    // static textTest(text: string) {
    //     return axios.post(`${apiUrl}/api/text`, { text }, { withCredentials: true })
    //         .then((response) => {
    //             return response.data;
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }

}