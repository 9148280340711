import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../../interfaces';
import { ButtonsMessage2, LogoFollower } from '../..';
import moment from 'moment';
import useConfigBotStore from '../../../store/config-bot-store';
import { getTextColor } from '../../../utils/colors/get-text-color';
import { relative } from 'path';

interface WrapperMessageProps {
    children: React.ReactNode;
    buttons?: Button[];
    userType: 'human' | 'bot';
    date?: Date;
    textLength?: number;
}


export const WrapperMessage2 = ({ children, buttons, userType, date, textLength }: WrapperMessageProps) => {

    const configBot = useConfigBotStore(state => state.configBot);

    const [buttonsVisible, setButtonsVisible] = useState(buttons?.length > 0);



    /**
     * La primera versi/on, comentada, envolvia el contenido y los botones en un mismo div
     * Su funcionamiento era perfecto, pero el avatar se centraba entre ambos y quedaba mal.
     * 
     * Con la segunda versión, el avatar se centra correctamente, pero los botones quedan fuera del div
     * del contenedor, por lo que se tuvo que hacer un arreglo y poner un avatar "invisible" junto 
     * los botones para que se centraran correctamente.
     */

    return (
        <div className='w-full flex px-3'>
            <div className='flex flex-col flex-1'>
                <div className='flex flex-row relative'>

                    <LogoFollower childrenLogo={
                        (
                            <>
                                {userType === 'bot' && (
                                    // mr-2 -ml-1 // y tambien el tamaño era de w-[40px] h-[40px]
                                    <div className='mr-3 py-2.5 flex items-end justify-center'>
                                        <span className='rounded-full bg-gray-950 w-[35px] h-[35px] overflow-hidden'>
                                            <img src={configBot.logo} alt="logo_bot" className="w-full h-full object-cover" />
                                        </span>
                                    </div>
                                )}
                            </>
                        )
                    }>

                        {/* ml-5  para el bot si se hace lo de poner el avatar arriba */}
                        <div
                            className={clsx(
                                'px-4 py-2.5 rounded-2xl my-2',
                                'inline-flex',
                                {
                                    'ml-auto w-auto bg-gray-400': userType === 'human',
                                    'mr-auto w-auto bg-bubble border border-bubble': userType === 'bot'
                                }
                            )}
                            style={{
                                border: userType === 'human' ? `1px solid ${configBot?.color3}` : "",
                                backgroundColor: userType === 'human' ? configBot?.color3 : "",
                                color: userType === 'human' ? getTextColor(configBot?.color3) : ""
                            }}
                        >
                            {children}
                        </div>
                    </LogoFollower>

                    {/* AVATAR DEL BOT */}
                    {/* style={{ position: 'absolute' }} */}
                    {/* El avatar era de 30px realmente */}




                    {/* <span> {dateFormatted}</span> */}





                </div>



                {/* Contenedor para centrar ButtonsMessage */}
                {/* {userType === 'bot' && buttonsVisible && (
                    <div className="flex justify-center">

                        <div className='mr-3 flex items-center justify-center'>
                            <span className='rounded-full bg-gray-950 w-[30px] h-[30px] invisible'>
                                <img src="/logo192.png" alt="imagen" />
                            </span>
                        </div>

                        <ButtonsMessage
                            buttons={buttons ?? []}
                            buttonsVisible={buttonsVisible}
                            setButtonsVisible={setButtonsVisible} />
                    </div>
                )} */}

            </div>
        </div >
    );
}

